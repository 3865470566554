<template>
    <div>
        <!-- Sort By Popup -->
        <v-dialog v-show="sortByPopup" v-model="sortByPopup" persistent max-width="290">
            <v-card>
                <v-card-title class="d-flex justify-space-between align-center">    
                    <p class="ma-0 body-heading font-weight-semi-bold">{{ $t("Customer.sortBy.Sort_By")}}</p>

                    <img src="https://s3iconimages.mymedicine.com.mm/cancel_icon_black.svg" alt="Close" @click="sortByPopupClose()">
                </v-card-title>

                <v-divider></v-divider>

                <v-card-actions class="px-4 py-4">
                    <div class="d-flex-column align-center sort-options-container">
                        <div v-if="page_name != 'All Categories'" class="pa-2 d-flex justify-space-between align-center" @click="sortByPopupClose(sortByValue == 'LowToHigh' ? null : 'LowToHigh')">
                            <p class="ma-0 body-text font-weight-medium" :class="[sortByValue == 'LowToHigh' ? 'light-blue-color-text' : 'black-color-text']">{{ $t("Customer.sortBy.Low_to_High_Price")}}</p>

                            <img v-if="sortByValue == 'LowToHigh'" src="https://s3iconimages.mymedicine.com.mm/select_button_blue.svg" alt="Selected">
                        </div>
                        
                        <div v-if="page_name != 'All Categories'" class="pa-2 d-flex justify-space-between align-center" @click="sortByPopupClose(sortByValue == 'HighToLow' ? null : 'HighToLow')">
                            <p class="ma-0 body-text font-weight-medium" :class="[sortByValue == 'HighToLow' ? 'light-blue-color-text' : 'black-color-text']">{{ $t("Customer.sortBy.High_to_Low_Price")}}</p>

                            <img v-if="sortByValue == 'HighToLow'" src="https://s3iconimages.mymedicine.com.mm/select_button_blue.svg" alt="Selected">
                        </div>

                        <div class="pa-2 d-flex justify-space-between align-center" @click="sortByPopupClose('AToZ')">
                            <p class="ma-0 body-text font-weight-medium" :class="[sortByValue == 'AToZ' ? 'light-blue-color-text' : 'black-color-text']">{{ $t("Customer.sortBy.A-Z")}}</p>

                            <img v-if="sortByValue == 'AToZ'" src="https://s3iconimages.mymedicine.com.mm/select_button_blue.svg" alt="Selected">
                        </div>

                        <div class="pa-2 d-flex justify-space-between align-center" @click="sortByPopupClose('ZToA')">
                            <p class="ma-0 body-text font-weight-medium" :class="[sortByValue == 'ZToA' ? 'light-blue-color-text' : 'black-color-text']">{{ $t("Customer.sortBy.Z-A")}}</p>

                            <img v-if="sortByValue == 'ZToA'" src="https://s3iconimages.mymedicine.com.mm/select_button_blue.svg" alt="Selected">
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Filter Popup -->
        <v-dialog v-if="filters && filters.length > 0" v-model="filterPopup" class="filter-popup" persistent>
            <div class="filter-body white-background">
                <!-- Filter Header -->
                <div class="filter-header-segment white-background">
                    <div class="pa-4 d-flex justify-space-between align-center">
                        <div class="d-flex align-center">
                            <img src="https://s3iconimages.mymedicine.com.mm/back_button.svg" alt="back-button" class="back-icon-class" @click="filterPopup=false">

                            <p class="ma-0 pl-2 body-heading font-weight-semi-bold">{{ $t("Customer.priceRangeComponent.Filters")}}</p>
                        </div>

                        <a v-if="filtersAppliedFlag" class="body-text font-weight-regular" @click="resetFilters()">{{ $t("Customer.priceRangeComponent.Clear_Filters")}}</a>
                    </div>

                    <v-divider></v-divider>
                </div>

                <!-- Filter Body -->
                <div class="filter_div">
                    <v-tabs vertical v-model="tab_id" class="light-grey-background">
                        <v-tab class="body-text font-weight-medium filter-tab" v-for="tab, index in filters" :key="index" align="left">
                            {{ tab.name }}
                        </v-tab>

                        <v-tab-item>
                            <div v-for="(category, index) in subCategories" :key="index" class="subcategory-item">
                                <label class="subcategory-name">{{ category.cat_name }}</label>
                                <input type="checkbox" class="pl-4 subcategory-checkbox-mobile"
                                    :checked="selectedSubCategories.includes(category.cat_name)"
                                    @click="addToSelectedSubcategories(category.cat_name);">
                            </div>
                        </v-tab-item>

                        <v-tab-item v-if="priceFilters && priceFilters.length > 0">
                            <div class="ma-4 px-4 pt-4 rounded-lg light-grey-border">
                                <div class="pb-2 d-flex justify-space-between">
                                    <div class="pl-md-2 d-sm-flex flex-column">
                                        <p class="ma-0 body-text font-weight-medium">{{ $t("Customer.priceRangeComponent.Min")}}</p>
                                        <p class="ma-0 body-text font-weight-semi-bold dark-blue-color-text"><b>{{ priceFilters[0] }}</b> KS</p>
                                    </div>

                                    <div class="pl-md-2 d-flex flex-column">
                                        <p class="ma-0 body-text font-weight-medium">{{ $t("Customer.priceRangeComponent.Max")}}</p>
                                        <p class="ma-0 body-text font-weight-semi-bold dark-blue-color-text"><b>{{ priceFilters[1] }}</b> KS</p>
                                    </div>
                                </div>

                                <div class="pa-sm-2 price-range-slider">
                                    <v-range-slider
                                        v-model="priceFilters"
                                        :min="minPrice"
                                        :max="maxPrice"
                                        :step="step"
                                        height="20"
                                        thumb-color="white"
                                        class="ma-0"
                                    >
                                    </v-range-slider>
                                </div>
                            </div>
                        </v-tab-item>
                    </v-tabs>
                </div>
                <div class="apply_filter">
                    <v-btn elevation="0" class="full-width-button text-transform-none-class body-heading white-color-text" color="rgba(72, 172, 239, 1)" @click="applyFilter">{{ $t("Customer.priceRangeComponent.Apply_Filter")}}</v-btn>
                </div>                
            </div>
        </v-dialog>

        <div class="pa-4 d-flex justify-space-between align-center header-container">
            <div class="d-flex justify-space-between align-center">
                <img src="https://s3iconimages.mymedicine.com.mm/back_button.svg" alt="back-button" class="back-icon-class" @click="redirectToPreviousPage()">
                
                <p class="ma-0 pl-2 body-heading font-weight-semi-bold" align="left">{{ page_name }}</p>
            </div>

            <div class="d-flex justify-space-between align-center">
                <!-- <img src="https://s3iconimages.mymedicine.com.mm/blue_search_outline.svg" alt="search-button" class="mx-2 icon-class rounded-lg"> -->
                <img v-if="icon_show_status.filter_icon" src="https://s3iconimages.mymedicine.com.mm/filter_icon_primary_light.svg" alt="filter-button" class="icon-class mr-2" @click="filterPopup=true;">
                <img v-if="icon_show_status.sort_icon" src="https://s3iconimages.mymedicine.com.mm/sort_by_icon_primary_light.svg" alt="sortby-button" class="icon-class ml-2" @click="sortByPopup=true">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'categoryHeaderMobileComponent',
    components: {
        'price-range-segment': () => import('../ePharmacyComponents/priceRangeComponent.vue')
    },
    props: {
        page_name: {
            type: String,
            required: true
        },
        icon_show_status: {
            type: Object,
            required: true
        },
        filters: {
            type: Array,
            required: false
        },
        sortValue: {
            type: String,
            required: false
        },
        selectedSubCategoriesName: {
            type: Array,
        },
        priceFilterRangeFromChild: {
            type: Boolean,
        }
    },
    data() {
        return {
            screenWidth: window.innerWidth,
            selectedSubCategoriesEmptyFlag: false,
            padding: 0,
            sortByPopup: false,
            sortByValue: 'AToZ',
            tab_id: null,
            filterPopup: false,
            minPrice: 0,
            maxPrice: 150000,
            step: 1000,
            sortOrder: {
                sortField: 'alphabetical',
                sortValue: 'AToZ'
            },
            priceFilters: null,
            subCategories: [],
            selectedSubCategories: [],
            selectedSubCategoryIds: [],
            appliedMinPrice: 0,
            appliedMaxPrice: 150000,
            appliedSelectedSubCategoryIds: [],
            appliedSelectedSubCategories: [],
            filtersAppliedFlag: false,
        }
    },
    watch: {
        filterPopup: function() {
            this.toggleBottomNavigation();
        },
        selectedSubCategories: function() {
            let subCategories = this.subCategories.filter((subcategory) => this.selectedSubCategories.includes(subcategory.cat_name));
            this.selectedSubCategoryIds = subCategories.map((category) => category.cat_id);
        },
        selectedSubCategoriesName(newVal, oldVal) {
            // This function will be called when headerProp changes
            if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
            this.selectedSubCategories = newVal;
            let subCategories = this.subCategories.filter((subcategory) => newVal.includes(subcategory.cat_name));
            this.selectedSubCategoryIds = subCategories.map((category) => category.cat_id);
            this.appliedSelectedSubCategoryIds = this.selectedSubCategoryIds;
            this.applyFilter();
            }
        },
        priceFilterRangeFromChild(newVal, oldVal) {
            if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                this.priceFilters = [0, 150000]
                this.applyFilter();
            }
        }
    },
    mounted() {
        let subCat = this.subCategories.filter((subcategory) => this.selectedSubCategoriesName.includes(subcategory.cat_name));
        this.selectedSubCategoryIds = subCat.map((category) => category.cat_id);
        if (this.filters) {
            if (this.filters.length == 2) {
                this.subCategories = this.filters[0].filters;
                this.priceFilters = [this.filters[1].min_amount, this.filters[1].max_amount];

                this.minAmount = this.priceFilters[0];
                this.maxAmount = this.priceFilters[1];

                let subCategories = this.subCategories.filter((subcategory) => this.selectedSubCategories.includes(subcategory.cat_name));
                this.selectedSubCategoryIds = subCategories.map((category) => category.cat_id);
                this.appliedSelectedSubCategoryIds = this.selectedSubCategoryIds;
            } else if (this.filters.length == 1) {
                this.priceFilters = [this.filters[0].min_amount, this.filters[0].max_amount];

                this.minAmount = this.priceFilters[0];
                this.maxAmount = this.priceFilters[1];
            }
        }
    },
    methods: {
        applyFilter() {
            this.filtersAppliedFlag = true;
            if (this.page_name == 'Categories') {
                if (this.selectedSubCategories.length == 0) {
                    this.selectedSubCategoriesEmptyFlag = true;
                } else {
                    // filters API.
                    if (this.sortByValue) {
                        this.sortOrder = {
                            sortField: this.sortByValue === 'AToZ' || this.sortByValue === 'ZToA' ? 'alphabetical' : 'price',
                            sortValue: this.sortByValue
                        };
                    } else {
                        this.sortOrder = {
                            sortField: 'alphabetical',
                            sortValue: 'AToZ'
                        };
                    }
                }
            }
            this.appliedSelectedSubCategoryIds = this.selectedSubCategoryIds;
            this.appliedMaxPrice = this.priceFilters[1];
            this.appliedMinPrice = this.priceFilters[0];
            this.$emit('refreshData', this.sortOrder, this.priceFilters[0], this.priceFilters[1], this.selectedSubCategoryIds);
            this.filterPopup = false;
        },
        resetFilters() {
            if (this.filters && this.filters.length > 0) {
                this.priceFilters = [this.filters[1].min_amount, this.filters[1].max_amount];
                
                this.minAmount = this.priceFilters[0];
                this.maxAmount = this.priceFilters[1];
                this.selectedSubCategories  = []
                let subCategories = this.subCategories.filter((subcategory) => this.selectedSubCategories.includes(subcategory.cat_name));
                this.selectedSubCategoryIds = subCategories.map((category) => category.cat_id);
            }
            this.filtersAppliedFlag = false;
            this.$router.go();
        },
        sortByPopupClose(sortByValue) {
            if (sortByValue != null) { 
                if (this.sortByValue !== sortByValue) {
                    this.sortByValue = sortByValue;
                    this.sortOrder.sortValue = sortByValue;
                }else{
                    this.sortOrder.sortValue = (sortByValue === 'AToZ') ? 'ZToA' : 'ZToA';
                }
                
                // Sort API call.
                this.sortOrder = {
                    sortField: (this.sortByValue === 'AToZ' || this.sortByValue === 'ZToA') ? 'alphabetical' : 'price',
                    sortValue: this.sortByValue
                };
                if (this.appliedSelectedSubCategoryIds.length)
                    this.$emit('refreshData', this.sortOrder, this.appliedMinPrice || this.minPrice, this.appliedMaxPrice || this.maxPrice, this.appliedSelectedSubCategoryIds);
                else
                    this.$emit('refreshData', this.sortOrder, this.appliedMinPrice || this.minPrice, this.appliedMaxPrice || this.maxPrice, []);

            }
            
            this.sortByPopup = false;
        },
        closeEmptySelectedCategoriesPopup() {
            this.selectedSubCategoriesEmptyFlag = false;
            this.selectedSubCategories = this.filters[0].filters;
        },
        toggleBottomNavigation() {
            this.$emit('toggleBottomNavigation', !this.filterPopup);
        },
        redirectToPreviousPage() {
            this.$router.go(-1);
        },
        addToSelectedSubcategories(subcategory) {
            if (this.selectedSubCategories.includes(subcategory)) {
                this.selectedSubCategories = this.selectedSubCategories.filter(cat_name => cat_name !== subcategory);
            }else{
                this.selectedSubCategories.push(subcategory);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../scss/global.scss';
@import '../../scss/classes.scss';

.header-container, .sortby-container, .sort-options-container {
    width: 100%;
}
.filter_div {
    overflow-y: scroll;
    height: 100% !important;
    margin-bottom: 10px;
}
.apply_filter {
    margin-top: 20px;
    position: fixed;
    bottom: 0;
    right: 0;
    height: 58px;
    box-shadow: 0px -4px 24px rgba(0, 36, 74, 0.1);
    background: rgba(255, 255, 255, 1);
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    z-index: 999;
}
.filter-popup {
    z-index: 999;
}
.filter-body {
    box-sizing: border-box;
    background-color: white;
    bottom: 0;
    left: 0;
    z-index: 999;
    position: absolute !important;
    height: 100%;
    overflow-y: scroll;
    padding: 0 !important;
}
.filter-body a {
    text-decoration: none;
}
.back-icon-class {
    width: auto;
    height: 24px;
    object-fit: fill;
}
.black-color-text {
    color: #333333;
}
h2 {
    text-align: left;
}

h3 {
    text-align: left;
    font-weight: 500;
}

.filter-header-segment {
    top: 0;
    position: sticky;
    z-index: 999;
}
</style>

<style lang="scss">
.subcategory-checkbox .v-input__slot {
    margin: unset !important;
    padding: unset !important;
}

.price-range-slider .v-slider--horizontal .v-slider__track-container {
    height: 5px !important;
}

.price-range-slider .v-slider__thumb:before {
    // width: 80px;
    // height: 80px;
    // border-radius: 40px;
    color: transparent !important;
}

.price-range-slider .v-slider__thumb:after {
    // width: 80px;
    // height: 80px;
    // border-radius: 40px;
    color: transparent !important;
}

.price-range-slider .v-slider__thumb {
    border: 1px solid #1467BF;
}

.price-range-slider .v-slider--horizontal {
    margin: 0px;
}

.filter-tab.v-tab {
    justify-content: flex-start;
}
.subcategory-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.subcategory-checkbox-mobile {
  margin-left: 10px;
  order: -1;
  height: 20px;
  width: 34px;
  left: -12px;
  margin:7px;
  border-radius: 50%;
  cursor: pointer;
  top: calc(50% - 24px);

}

.subcategory-name {
  margin: 0;
  flex: 1;
  text-align: left;
  position: relative;
  left: 0;
  right: auto;
  font-size: 16px;
}
</style>